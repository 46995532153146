export default function init() {
  if (!document.querySelector('.js-tertiary-nav')) return;

  import('priority-nav').then(({ default: priorityNav }) => {
    priorityNav.init({
      mainNavWrapper: '.js-tertiary-nav__nav',
      mainNav: '.js-tertiary-nav__list',
      navDropdownClassName: 'c-tertiary-nav__dropdown',
      navDropdownToggleClassName: 'c-tertiary-nav__button',
      navDropdownLabel: 'More',
      navDropdownBreakpointLabel: 'More',
    });
  });
}
